import { Button, Col, Row, Modal, Card, Container, ListGroup, ListGroupItem, Badge } from "react-bootstrap";
import { api } from "@/apps/common/api-client";
import { useEffect, useState, useMemo } from "react";
import { useRequest } from "ahooks";
import { AdminNoteType, AccountType } from "@/apps/common/types/appointment-types";
import { useAuthStore } from "@/apps/common/store/useAuthStore";
import Table from "@/apps/common/components/Table";
import API_BASE_URL from "@/config"
import { useNavigate } from "react-router-dom";
//import userAvatar from "/src/assets/images/users/avatar-11.png"
import FiltersBar from "../MemberService/filtersBar";
import AccountForm from "@/apps/common/components/Accounts"
//
//import userAvatar from "../../../assets/images/users/avatar-11.png";
//import { Link } from "react-router-dom";

// components
import PageTitle from "@/apps/common/components/PageTitle";
//import MemberServiceDetails from "../../components/MemberServiceDetails/MemberServiceDetails";



export default function Accounts() {

    type rowData = {
        id: number
        name: string
        email: string
        phoneNumber: string
    }

    const [selectedAccount, setSelectedAccount] = useState<AccountType | null>(null)
    const [showCreateAccount, setShowCreateAccount] = useState<boolean>(false)
    const [localDomains, setLocalDomains] = useState<boolean>(false)
    const [mode, setMode] = useState<"preview" | "edit" | "register" | "admin" | "create">('preview')
    const [filters, setFilters] = useState<any>({

    });

    const navigate = useNavigate();

    const handleRowClick = (row: any) => {
        const account = accounts?.find((x) => {
            return (x.id == row.values.id)

        })
        setSelectedAccount(account!)
        setMode('edit')
        setShowCreateAccount(true)
        //setDisplayDetails(true)


    };

    const columns = [
        {
            Header: "Id",
            accessor: "id",
            sort: true,
            Cell: ({ row }: { row: any }) => (
                < a
                    onClick={() => handleRowClick(row)}
                >
                    {row.values.id}
                </a>
            )
        },
        {
            Header: "Name",
            accessor: "name",
            sort: true,
            Cell: ({ row }: { row: any }) => (
                < a
                    onClick={() => handleRowClick(row)}
                >
                    {row.values.name}
                </a>
            )
        },
        {
            Header: "email",
            accessor:
                "email",
            sort: false,
            Cell: ({ row }: { row: any }) => (
                < a
                    onClick={() => handleRowClick(row)}
                >
                    {row.values.email}
                </a>
            )
        },
        {
            Header: "phoneNumber",
            accessor:
                "phoneNumber",
            sort: true,
            Cell: ({ row }: { row: any }) => (
                < a
                    onClick={() => handleRowClick(row)}
                >
                    {row.values.phoneNumber}
                </a>
            )
        },
        // {
        //     Header: "state",
        //     accessor:
        //         "state",
        //     sort: true,
        //     Cell: ({ row }: { row: any }) => (
        //         < a
        //             onClick={() => handleRowClick(row)}
        //         >
        //             {row.values.state}
        //         </a>
        //     )

        // },
        // {
        //     Header: "createdAt",
        //     accessor:
        //         "createdAt",
        //     sort: true,
        //     Cell: ({ row }: { row: any }) => (
        //         < a
        //             onClick={() => handleRowClick(row)}
        //         >
        //             {new Date(row.values.createdAt).toDateString()}
        //         </a>
        //     )

        // },

    ];

    const [accounts, setAccounts] = useState<AccountType[]>()
    const [displayDetails, setDisplayDetails] = useState<boolean>(false)
    const handleClose = () => setShowCreateAccount(false)

    const { data: _accounts = [] } = useRequest(
        () =>

            fetchAllAccounts(filters),
        {
            refreshDeps: [filters, showCreateAccount],
            debounceWait: 300
        }
    );

    async function fetchAllAccounts(filters: string) {

        api.accounts.fetchAllAccounts({ filters }).then((res) => {
            console.debug('Fetached all members - ' + res.length)
            setAccounts(res)
            setRows([])

            res?.forEach((account: AccountType) => {

                // if (user?.admin_notes.length > 0)
                //     console.debug('member - ' + JSON.stringify(user?.admin_notes))

                const row = {
                    id: account?.id,
                    name: account?.name ? account?.name : 'N/A/',
                    email: account?.email ? account?.email : "N/A",
                    phoneNumber: account?.phoneNumber ? account?.phoneNumber : "N/A",
                    //appointmentDate: x?.appointment?.timeSlot?.startDate ? new Date(x?.appointment?.timeSlot?.startDate).toString() : "N/A",
                }
                //showTable = true;
                setRows(prev => [...prev, row])
            })
            return res
        })
    }

    useEffect(() => {
        if (API_BASE_URL.API_URL.includes('localhost') ||
            API_BASE_URL.API_URL.includes('dev') ||
            API_BASE_URL.API_URL.includes('beta')) {
            setLocalDomains(true)
        }
    }, [accounts]);


    const a: rowData[] = [];
    const [rows, setRows] = useState(a);

    return (
        <>
            <PageTitle
                title={"Accounts"}
            />
            {/* 
            <Row>
                <Col>
                    <FiltersBar values={filters} onChange={setFilters} />
                </Col>
            </Row> */}

            <Row >
                <Button style={{ width: '200px' }} onClick={() => { setSelectedAccount(null); setMode('create'); setShowCreateAccount(true) }}>
                    Create New Account
                </Button>
            </Row>

            <div className="p-1 m-2">
                <Table
                    columns={columns}
                    data={rows}
                    pageSize={20}
                    isSortable={true}
                    pagination={true}
                    isSearchable={true}
                />
            </div>

            <Modal show={showCreateAccount} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{mode === "create" ? 'Create New Account' : 'Edit/Preview Account Details'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>

                        <AccountForm account={selectedAccount} mode={mode} updateData={() => { }} display={setShowCreateAccount} />

                    </Container>
                </Modal.Body>

            </Modal>
        </>
    );
}


