import { AxiosInstance } from "axios";
import { BaseApiModule } from "./base.module";
import { unstrapiMany, unstrapiOne } from "../../helpers/strapi";
import { AccountType } from "../../types/appointment-types";

export class AccountApiModule extends BaseApiModule {
  constructor(client: AxiosInstance) {
    super(client, "api");
  }


  create(data: any) {
    return this.client
      .post("/api/accounts", { data })
      .then((res) => res.data);
  }

  fetchAllAccounts({ populate, filters }: { populate?: string[], filters?: any } = {}) {
    return this.client
      .get(`api/accounts`, {
        params: {
          populate,
          // filters:{
          //   users:{
          //     $in: [279]
          //   }
          // }
          filters
        }
      })
      .then((res) =>{
        const resp = unstrapiMany<AccountType>(res.data, "account")
        //console.debug ('Fetch Accounts - ' + JSON.stringify(resp))
        return resp;
      }
      );
  }

  update(id: number, data: any) {
    //console.debug('updating service provider profile - ' + JSON.stringify(data))
    return this.client
      .put(`api/accounts/${id}`, { data })
      .then((res) =>
        unstrapiOne<AccountType>(res.data, "account")
      );
  }

}
