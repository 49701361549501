import { generateForm } from "@modules/react-jsonschema-form-bootstrap";
import validator from "@rjsf/validator-ajv8";
import { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { api } from "../api-client";
import { useProviderProfileFormConfig } from "../forms/ProviderProfile";
import { ServiceProviderType, StateType, ServiceType, ServiceProvidertypeType, LanguageType, UserType } from "../types/appointment-types";
import { LegacyUserType } from "@/apps/common/api-client/api-client.types";
import { useRequest } from "ahooks";
import { useAuthStore } from "@/apps/common/store/useAuthStore";
import { unstrapiMany, unstrapiOne } from "@/apps/common/helpers/strapi";

export type ServiceProviderProfileType = {

  //type: string;
  //user: UserType | null;
  firstName: string,
  lastName: string,
  phoneNumber: string,
  email: string,
  state: number,
  status: "pending" | "approved" | null;
  about: string;
  address: string;
  qualification?: string;
  states: StateType[];
  referralFees?: number;
  fullProfileLink?: string;
  serviceTypes?: ServiceType[];
  npi?: string;
  tags?: string[];
  service_provider_types?: ServiceProvidertypeType[]
  languages?: LanguageType[]
}


const Form = generateForm<Partial<ServiceProviderType>>();

export default function ProviderProfileForm({
  mode,
  serviceProvider,
  updateData,
  providerProfileFormData
}: {
  mode: "register" | "admin" | "create" | "edit" | "preview";
  serviceProvider: Partial<ServiceProviderType> | null;
  updateData: (data: any) => void;
  providerProfileFormData: (data: any) => void;
  // display: (value: boolean) => void;
}) {

  //const [serviceProviderData, setServiceProviderData] = useState<Partial<ServiceProviderType>>();
  const [serviceProviderData, setServiceProviderData] = useState<Partial<ServiceProviderProfileType>>();
  const [submitMessage, setSubmitMessage] = useState<string>();
  const [displaySubmitMessage, setDisplaySubmitMessage] = useState<boolean>(false);
  const { schema, uiSchema, customValidate, transformErrors } =
    useProviderProfileFormConfig({
      mode,
    });

  const showAlert = () => {
    setDisplaySubmitMessage(true)
    const timeoutId = setTimeout(() => {
      setDisplaySubmitMessage(false);
    }, 2000);
  }

  //console.debug ('service_provider_types - ' + JSON.stringify(serviceProvider?.service_provider_types))
  const setValues = () => {
    console.debug('SetValues - ' + JSON.stringify(serviceProvider))
    const data = {
      ...(serviceProvider
        ? {
          //...serviceProvider,
          firstName: serviceProvider?.user?.firstName,
          lastName: serviceProvider?.user?.lastName,
          phoneNumber: serviceProvider?.user?.phoneNumber,
          email: serviceProvider?.user?.email,
          state: serviceProvider?.user?.state?.id,
          npi: serviceProvider?.npi,
          status: serviceProvider.status ? serviceProvider.status : "pending",
          states: serviceProvider.states,
          serviceTypes: serviceProvider.serviceTypes,
          service_provider_types: serviceProvider?.service_provider_types,
          languages: serviceProvider?.languages,
          address: serviceProvider?.address,
        }
        : {}
      )
    };

    //console.debug('setting values ' + JSON.stringify(data))
    return data
  }

  const { loading: saving, runAsync: updateUser } = useRequest(
    api.auth.updateUser.bind(api.auth),
    {
      onSuccess: (result, params) => {
        try {
          console.debug("user update succeeded - " + JSON.stringify(result));
          useAuthStore.getState().fetchUser();
        } catch (error) {
          console.debug("user update failed - " + JSON.stringify(error));
          console.error(error);
        }
      },
      manual: true
    }
  );

  useEffect(() => {
    setServiceProviderData(setValues())
  }, [serviceProvider]);

  const onSubmit = (form: any) => {

    console.debug('submitting Provider Profile data - ' + JSON.stringify(form.formData))
    const formData = form?.formData;
    if (!formData?.npi) {
      formData.npi = ''
    }

    if (mode === "register") {
      providerProfileFormData(formData)
      return
    }

    if (serviceProvider?.id) {

      console.debug('formdata.state' + JSON.stringify(formData.state))
      const updateUserData: Partial<LegacyUserType> = {

        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
        email: formData.email,
        username: formData.email,
        state: formData.state
      };
      console.debug('Updating user data')
      updateUser(serviceProvider.user!.id, updateUserData).then(
        (res) => {

        },
        (err) => {
          setSubmitMessage("An error occured while updating the Provider details. Please try again.")
          showAlert()
        }
      )

      api.serviceProviders.update(serviceProvider.id, form.formData).then(
        (res) => {

          //console.debug('updateUserData -' + JSON.stringify(updateUserData))
          if (res.id !== undefined) {
            api.serviceProviders.find(res.id)?.then(
              (resData) => {
                console.debug('successfully updated service provider profile - ' + JSON.stringify(resData))
                setSubmitMessage("Provider details were updated successfully !")
                showAlert()
                updateData(resData)
                let a: Partial<ServiceProviderProfileType>
                a = {
                  ...resData,
                }
                a.firstName = updateUserData.firstName
                a.lastName = updateUserData.firstName
                a.phoneNumber = updateUserData.phoneNumber
                a.email = updateUserData.email
                a.state = updateUserData.state?.id
                setServiceProviderData(resData)
              }
            )
          }
        },
        (err) => {
          //console.debug('error updating service provider profile - ' + JSON.stringify(err)) 
          setSubmitMessage("An error occured while updating the Provider details. Please try again.")
          showAlert()
        }
      )
    }
  };

  return (
    <>
      {
        <>
          <br />
          {serviceProvider || mode === "register" ?
            (<Form
              formContext={serviceProviderData}
              //formData={serviceProviderData as Partial<ServiceProviderType>}
              formData={serviceProviderData as Partial<ServiceProviderProfileType>}
              schema={schema as any}
              uiSchema={uiSchema as any}
              validator={validator}
              showErrorList={false}
              customValidate={customValidate}
              noHtml5Validate
              liveValidate={true}
              transformErrors={transformErrors}
              onSubmit={onSubmit}
            ></Form>) : null
          }
          <br />
          <br />
          <Alert variant="secondary" className="mb-0 d-md-block d-sm-none" show={displaySubmitMessage} >
            {submitMessage}
          </Alert>
        </>

      }
    </>

  );

}
