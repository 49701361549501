import axios, { type AxiosInstance, type AxiosResponse } from "axios";
import { ClientConfig, Tokens } from "./api-client.types";
import { AuthApiModule } from "./modules/auth-client.module";
import { CommonApiModule } from "./modules/common.module";
import { ReferralsApiModule } from "./modules/referrals.module";
import { MemberServiceApiModule } from "./modules/member-services.module";
import { TelehealthLocationApiModule } from "./modules/telehealthLocation.module";
import { AdminNotesApiModule } from "./modules/adminNotes.module";
import { AccountApiModule } from "./modules/account.module";
import { HealthInsuranceApiModule } from "./modules/health-insurance.module";
import { InsuranceProviderApiModule } from "./modules/insurance-providers.module";
import { EvaluationApiModule } from "./modules/evaluation.module";
import { AppointmentsApiModule } from "./modules/appointments.module";
import { PaymentsApiModule } from "./modules/payment-methods.module";
import { ServiceProvidersApiModule } from "./modules/service-provider.module";
import { ContractsApiModule } from "./modules/contracts.module";
import { UserApiModule } from "./modules/user.module";
import { MemberEventsApiModule } from "./modules/member-events.module";

const TOKEN_STORE = "appTokens";

interface JWTErrorResponse {
  errors: [{ extensions: { code: string } }];
}

export default class APIClient {
  client: AxiosInstance;
  tokens?: Tokens;

  readonly auth: AuthApiModule;
  readonly common: CommonApiModule;
  readonly referrals: ReferralsApiModule;
  readonly memberServices: MemberServiceApiModule;
  readonly evaluations: EvaluationApiModule;
  readonly appointments: AppointmentsApiModule;
  readonly payments: PaymentsApiModule;
  readonly serviceProviders: ServiceProvidersApiModule;
  readonly contracts: ContractsApiModule;
  readonly telehealthLoc: TelehealthLocationApiModule;
  readonly healthInsurance: HealthInsuranceApiModule;
  readonly insuranceProvider: InsuranceProviderApiModule;
  readonly user: UserApiModule;
  readonly memberEvents: MemberEventsApiModule;
  readonly adminNotes: AdminNotesApiModule;
  readonly accounts: AccountApiModule;

  refreshPromise?: Promise<AxiosResponse<Tokens>>;

  constructor(private readonly config: ClientConfig) {
    const { baseURL } = config;

    this.client = axios.create({
      baseURL,
      withCredentials: false,
      timeout: 90000
    });

    this.setupInterceptors(this.client);
    this.auth = new AuthApiModule(this.client);
    this.common = new CommonApiModule(this.client);
    this.referrals = new ReferralsApiModule(this.client);
    this.memberServices = new MemberServiceApiModule(this.client);
    this.evaluations = new EvaluationApiModule(this.client);
    this.appointments = new AppointmentsApiModule(this.client);
    this.payments = new PaymentsApiModule(this.client);
    this.serviceProviders = new ServiceProvidersApiModule(this.client);
    this.contracts = new ContractsApiModule(this.client);
    this.telehealthLoc = new TelehealthLocationApiModule(this.client);
    this.healthInsurance = new HealthInsuranceApiModule(this.client);
    this.insuranceProvider = new InsuranceProviderApiModule(this.client);
    this.user = new UserApiModule(this.client);
    this.memberEvents = new MemberEventsApiModule(this.client);
    this.adminNotes = new AdminNotesApiModule(this.client);
    this.accounts = new AccountApiModule(this.client);
    this.readTokens();
  }

  private buildBearerAuthHeader(access: string) {
    return `Bearer ${access}`;
  }

  private readTokens() {
    const tokenString = localStorage.getItem(TOKEN_STORE);
    if (tokenString) {
      this.setTokens(JSON.parse(tokenString) as Tokens);
    }
  }

  clearTokens() {
    delete this.tokens;
    localStorage.removeItem(TOKEN_STORE);
    delete this.client.defaults.headers.common.Authorization;
  }

  setTokens(tokens: Tokens) {
    const existingTokens = this.tokens ?? {};
    this.tokens = Object.assign(existingTokens, tokens);
    this.client.defaults.headers.common.Authorization =
      this.buildBearerAuthHeader(tokens.jwt);
    localStorage.setItem(TOKEN_STORE, JSON.stringify(this.tokens));
  }

  setupInterceptors(client: AxiosInstance) {
    // Request interceptor for Refresh token call
    client.interceptors.response.use(
      async (response) => {
        const isTokenError =
          (response?.data as JWTErrorResponse)?.errors?.[0]?.extensions
            ?.code === "invalid-jwt";

        if (isTokenError) {
          // return await this._refreshTokenAndRetry(response);
        }

        return await Promise.resolve(response);
      },
      async (error) => {
        if (axios.isCancel(error)) {
          return await Promise.reject(error);
        }

        const isTokenError =
          error?.response?.status === 401 &&
          error?.response?.data?.error?.message ===
          "Missing or invalid credentials";
        if (isTokenError) {
          this.clearTokens();
        }

        return await Promise.reject(error);
      }
    );
  }
}
